<template>
<div>
  <div class="dialer-signup-form">
    <!-- veryfied -->
    <div v-if="conditions.screen==screens.verified">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner">
            <b-form @submit.prevent="''">
              <div class="emailHeading text-center mx-auto">Your invitation is verified</div>
              <div class="mainText mt-20px">
                The system has found a user with the 
                given activation string. The account 
                has been activated.
              </div>
              <div class="InvitedOrganizationsContainer-latest">
                <div v-for="org in response.verify.old" :key="org.id" class="InvitedOrganizationsBar">
                  <div>{{ org.label }}</div>
                  <div class="text-primary">{{ org.uid }}</div>
                </div>
                <div v-if="response.verify.current" class="InvitedOrganizationsBar">
                  <div>{{ response.verify.current.label }}</div>
                  <div class="text-primary">{{ response.verify.current.uid }}</div>
                </div>
              </div>
            </b-form>
          </div>
          <div class="bottomSection">
            Already have a account ? 
            <a @click="$router.push({ name: routesNames.login })" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
          </div>
        </div>
      </div>
    </div>
    <!-- not veryfied -->
    <div v-else-if="conditions.screen==screens.notverified">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner">
            <img src="../assets/images/emailNotVerifiedIMG.png" alt="icon" class="emailRightSideIMG"/>
            <div class="emailHeading">
              Oops! Your link is
              expired!
            </div>
            <div class="subText text-center mt-16px">
              <!-- The system is unable to find a user with 
              the given activation string. The account may 
              already be activated or their activation code 
              has expired. If it has expired, you need to
              re-send activation link. -->
              The system is unable to find a user with 
              the given invitation link.It may happen because the account may 
              already be activated, the invitation link is expired or they have run out of slots for adding more users.
              Please contact the person that sent you the link and ask him to resend it. 
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- signup_process -->
    <div v-else-if="conditions.screen==screens.signup_process" class="dialer-login-form ">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner">
            <b-form @submit.prevent="signup()">              
              <b-alert :show="!!api.signup.error_message" variant="danger">{{ api.signup.error_message }}</b-alert>
              <div class="mainInput-container mt-40px mb-20px with-error">
                <label>First name</label>
                <div :class="`mainInput ${api.signup.send?'disabled':''}`">
                  <b-form-input 
                    readonly 
                    onfocus="this.removeAttribute('readonly');" 
                    :maxlength="$v.forms.signup.firstName.$params.maxLength.max" 
                    :disabled="api.signup.send" 
                    v-model="forms.signup.firstName" 
                    type="text" 
                  />
                </div>
                <template v-if="(forms.signup.submitted && $v.forms.signup.firstName.$invalid) || api.signup.validation_errors.firstName">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p class=" animated bounceIntop">
                      <span v-if="!$v.forms.signup.firstName.required">* First name is required</span>
                      <span v-else-if="!$v.forms.signup.firstName.maxLength">
                        * First name is maximum {{$v.forms.signup.firstName.$params.maxLength.max}} character
                      </span>
                      <template v-else-if="api.signup.validation_errors.firstName">
                        <span v-for="(em, i) in api.signup.validation_errors.firstName" :key="i">* {{ em }}</span>
                      </template>
                    </p>
                  </div>
                </template>
              </div>
              <div class="mainInput-container mb-20px with-error">
                <label>Last name</label>
                <div :class="`mainInput ${api.signup.send?'disabled':''}`">
                  <b-form-input 
                    readonly 
                    onfocus="this.removeAttribute('readonly');" 
                    :maxlength="$v.forms.signup.lastName.$params.maxLength.max" 
                    :disabled="api.signup.send" 
                    v-model="forms.signup.lastName" 
                    type="text" 
                  />
                </div>
                <template v-if="(forms.signup.submitted && $v.forms.signup.lastName.$invalid) || api.signup.validation_errors.lastName">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p class="animated bounceIntop">
                      <!-- <span v-if="!$v.forms.signup.lastName.required">* Last name is required</span> -->
                      <span v-if="!$v.forms.signup.lastName.maxLength">
                        * Last name is maximum {{$v.forms.signup.lastName.$params.maxLength.max}} character
                      </span>
                      <template v-else-if="api.signup.validation_errors.lastName">
                        <span v-for="(em, i) in api.signup.validation_errors.lastName" :key="i">* {{ em }}</span>
                      </template>
                    </p>
                  </div>
                </template>
              </div>
              <div class="mainInput-container mb-20px with-error">
                <label>Password</label>
                <div :class="`mainInput password ${api.signup.send?'disabled':''}`">
                  <b-form-input 
                    readonly 
                    onfocus="this.removeAttribute('readonly');" 
                    :disabled="api.signup.send" 
                    :type="forms.signup.password_show?'text':'password'" 
                    v-model="forms.signup.password" 
                  />
                  <span class="pass-icon">
                    <b-icon 
                      class="dialer-showpassword-icon cursor_pointer" 
                      :icon="forms.signup.password_show ? 'eye-slash-fill' : 'eye-fill'" 
                      @click="api.signup.send ? '' : forms.signup.password_show=!forms.signup.password_show" 
                    />
                  </span>
                </div>
                <template v-if="(forms.signup.submitted && $v.forms.signup.password.$invalid) || api.signup.validation_errors.password">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p class="animated bounceIntop">
                      <span v-if="!$v.forms.signup.password.required">* Password is required</span>
                      <span v-else-if="!$v.forms.signup.password.capitalalphabet">* Password required at least one capital character</span>
                      <span v-else-if="!$v.forms.signup.password.smallalphabet">* Password required at least one small character</span>
                      <span v-else-if="!$v.forms.signup.password.numbers">* Password required at least one integer character</span>
                      <span v-else-if="!$v.forms.signup.password.specialcharacter">* Password required at least one special character</span>
                      <span v-else-if="!$v.forms.signup.password.minLength">
                        * Password is minimum {{$v.forms.signup.password.$params.minLength.min}} is required
                      </span>
                      <span v-else-if="!$v.forms.signup.password.maxLength">
                        * Password is maximum {{$v.forms.signup.password.$params.maxLength.max}} character
                      </span>
                      <template v-else-if="api.signup.validation_errors.password">
                        <span v-for="(em, i) in api.signup.validation_errors.password" :key="i">* {{ em }}</span>
                      </template>
                    </p>
                  </div>
                </template>
              </div>
              <div class="PasswordLength-LineContainer mb-16px">
                <label>Password strength</label>
                <div class="line-wrap">
                  <div 
                    :class="`line-itself ${(passwordStrength/5 * 100) > 60 ? 'strong' : ''} `" 
                    :style="`width: calc(${passwordStrength/5 * 100}% + ${passwordStrength>0 ? '2' : '0'}px);`"
                  />
                </div>
                <small class="line-text">{{passwordStrength | filterPasswordStrengthText}}</small>
              </div>
              <div v-if="GET_IS_RECAPTCHA_ENABLED" class="position-relative h-78px my-4">
                <app-recaptcha 
                  @load="forms.signup.recaptcha=false"
                  @verify="forms.signup.recaptcha=true"
                  @expire="forms.signup.recaptcha=false"
                  @fail="forms.signup.recaptcha=false"
                />
                <template v-if="(forms.signup.submitted && $v.forms.signup.recaptcha.$invalid)">
                  <div class="errorTemplate-v2" style="width:270px">
                    <p class=" animated bounceIntop">
                      <span v-if="!$v.forms.signup.recaptcha.required">* Recaptcha required</span>
                    </p>
                  </div>
                </template>
              </div>
              <!-- sign up button -->
              <b-button class="fullBlackBTN" type="submit" variant="primary">
                <app-spinner v-if="api.signup.send" />
                <template v-else>Submit</template>
              </b-button>
            </b-form>
          </div>
          <div class="bottomSection">
            Already have a account ? 
            <a @click="$router.push({ name: routesNames.login })" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div v-else-if="conditions.screen==screens.loading">
      <!-- <app-spinner class="big" />  -->
      <div class="latestShimmerDesign" style="width: 100vw;height: 100vh;"></div>
    </div>
  </div>
</div>

</template>

<script>
import { API } from '../service';
import { filterPasswordStrengthText } from '@/bootstrap/filters/filterPasswordStrengthText';
import required from "vuelidate/lib/validators/required";
import minLength from "vuelidate/lib/validators/minLength";
import maxLength from "vuelidate/lib/validators/maxLength";
import { mapGetters } from 'vuex';
import SipupSlider from '../components/SipupSlider.vue'
import { GET_IS_RECAPTCHA_ENABLED } from '@/store/constants/getters';
import { routes_names } from '@/router/constants/routes-names';
const screens = {
  loading: 'loading',
  verified: 'verified',
  signup_process: 'signup_process',
  notverified: 'notverified',
}
export default {
  name: 'Invitation',
  components: {
    SipupSlider
  },
  data(){
    return {
      api: {
        verifyinvitation: this.$app.api(),
        signup: this.$app.api({ 
          error_message: true, 
          validation_errors: true,
        }),
      },
      forms: {
        signup: this.$app.form({
          data: {
            firstName: '',
            lastName: '',
            telephone: '',
            password: '',
            password_show: false,
            recaptcha: false,
          }
        }),
      },
      response: {
        verify: {},
      },
      conditions: {
        screen: screens.loading,
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_IS_RECAPTCHA_ENABLED
    ]),
    routesNames(){ return routes_names },
    screens(){ return screens },
    passwordStrength(){
      const password = this.forms.signup.password
      return ~~[/[A-Z]/.test(password),/[0-9]/.test(password),/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password),/[a-z]/.test(password),password.length > 11,].reduce((a, b)=>b ? a + 1 : a)
    },
    key(){ return this.$route.params.key },
  },
  filters: {
    filterPasswordStrengthText,
  },
  watch: {
    "conditions.screen": {
      immediate: true,
      handler(screen){
        this.$emit('screen',screen)
      }
    },
  },
  validations: {
    forms: {
      signup: {
        firstName: {
          required,
          maxLength: maxLength(16),
        },
        lastName: {
          // required,
          maxLength: maxLength(16),
        },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(32),
          capitalalphabet: function(value){
            return /[A-Z]/.test(value)
          },
          smallalphabet: function(value){
            return /[a-z]/.test(value)
          },
          numbers: function(value){
            return /[0-9]/.test(value)
          },
          specialcharacter: function(value){
            return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value)
          },
        },
        recaptcha: {
          required: function(value){ 
            return this.GET_IS_RECAPTCHA_ENABLED ? value : true 
          },
        },
      },
    },
  },
  methods: {
    async signup(){
      this.forms.signup.submitted=true
      this.$v.forms.signup.$touch();
      if(this.api.signup.send || this.$v.forms.signup.$invalid) return;
      try {
        this.api.signup.send=true
        this.api.signup.validation_errors={}
        this.api.signup.error_message=''
        await API.endpoints.auth.invitationinfo({
          firstname: this.forms.signup.firstName,
          lastname: this.forms.signup.lastName,
          password: this.forms.signup.password,
          id: this.response.verify?.id,
        },{
          default_message: 'Invalid username or password'
        })
        this.$router.push({
          name: routes_names.login
        })
      } catch (ex) {
        this.api.signup.error = ex.own_errors || {}
        this.api.signup.error_message = ex.own_message
      } finally {
        this.api.signup.send=false
        this.forms.signup.submitted=false
      }
    },
    async verifyinvitation(){
      if(this.api.verifyinvitation.send) return;
      try {
        this.api.verifyinvitation.send=true
        const { data } = await API.endpoints.auth.verifyinvitation({
          id: this.key
        })
        this.response.verify=data
        const status = data?.status || ''
        if(status=='accepted'){
          this.conditions.screen=screens.signup_process
          const payload = JSON.parse(data?.data ?? "{}")
          this.forms.signup.firstName = payload?.firstname ?? ''
          this.forms.signup.lastName = payload?.lastname ?? ''
        } else if(status=='failed'){
          this.conditions.screen=screens.notverified
        } else {
          this.conditions.screen=screens.verified
        }
      } catch (ex) {
        console.log('ex',ex)
        this.conditions.screen=screens.notverified
      } finally {
        this.api.verifyinvitation.send=false
      }
    },
  },
  mounted(){
    this.verifyinvitation()
  },
}
</script>

<style>

</style>